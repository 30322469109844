import React, {useState} from 'react'
import { Layout } from '../components/layout'
import axios from "axios";
import { Link } from 'gatsby';
import { useQueryParam, StringParam, NumberParam } from "use-query-params";
import { FaRocket } from "@react-icons/all-files/fa/FaRocket"
import AdSection from '../components/ad-section'

export default function Contact() {
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });
    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://formspree.io/f/xlezwypw",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thanks! Your Inquiry has been submitted.", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };
    const [service, setService] = useQueryParam("s", StringParam);
    const [accreditation, setAccreditation] = useQueryParam("a", NumberParam);
    let newService = "";

    if(service) {
      newService = service.toLowerCase()
                          .replace(/-/g,' ');
    }

    const aabbAcreditation = <div className={['contact-form-aabb-notice','dark'].join(" ")}>{newService.toUpperCase()} - Our Lab is AABB Accredited to perform this testing</div>
    const defaultOptions = "Family Study, DNA Relationship Testing for Immigration, Legal/Court Admissible, Other";
    const homeKitSection = <div className={['contact-form-aabb-notice','dark'].join(" ")}><Link to="/products/home-kits/dna-collection-kit/" style={{display: "flex", flexFlow: "wrap", alignItems: "center"}} ><FaRocket /><p>Starting at $100 - Non-legal Paternity/Maternity Home Collection Kit. Includes testing for 2 people.</p></Link></div>;
      
      return (
          <Layout isLandingPage={true}>
              <div className="container">
                <AdSection />
                <div style={{margin: "20px 0"}}>
                <h1 className="title">Contact Form</h1>
                <p>
                  Call today at (708) 234-1200 to speak to one of our DNA Collection Specialists or fill out the form below. Business hours M-F, 9-5pm CST.
                </p>
                {accreditation ? aabbAcreditation : null}
                </div>
                <form className="contact-form" onSubmit={handleOnSubmit}>
                    <label htmlFor="email">Email:</label>
                    <input id="email" type="email" name="email" required />
                    <label htmlFor="service">Service needed:</label>
                    <input id="service" type="text" name="service" placeholder={newService ? newService : defaultOptions}
                    defaultValue={newService ? newService : ""}
                    required />
                    <label htmlFor="phone">Phone</label>
                    <input id="phone" type="tel" name="phone" placeholder="000-000-0000"  required />
                    <label htmlFor="reach">Best way to reach you:</label>
                    <input id="reach" type="text" name="reach" placeholder="Phone or Email"  required />
                    <label htmlFor="message">Message:</label>
                    <textarea id="message" name="message" required placeholder="For appointments, please let us know your availability. Our DNA Collection Specialist will contact you to confirm your appointment. Our business hours are M-F 9-5pm and Sat 9-1pm."></textarea>
                    <input type="hidden" name="_subject" id="email-subject" value="Relationship Testing Form Submission"></input>
                    <button type="submit" disabled={serverState.submitting} className="btn-general blue-background">
                    Submit
                    </button>
                </form>
                {serverState.status && (
                    <p className={!serverState.status.ok ? "errorMsg" : ""}>
                        {serverState.status.msg}
                    </p>
                )}
                {homeKitSection}
                </div>
          </Layout>
      );
}