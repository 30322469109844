import React from 'react'
import {container, textSection} from './ad-section.module.css'
import { StaticImage } from 'gatsby-plugin-image'

const color = ["#8C52FF", "#5271FF", "#FFBD59", "#FF914D", "#00C2CB"]

function randomColor() {
    const max = color.length;
    return color[Math.floor(Math.random() * max)]
}

export default function AdSection() {
    const backgroundColor = randomColor();

    return (
        <div className={container} style={{background: backgroundColor}}>
            <div style={{flex: "1", display: "flex", justifyContent: "center" , alignItems: "center"}}>
                <div style={{borderRadius: "1000px", overflow: "hidden", width: "100px", height: "100px"}}>
                <StaticImage imgStyle={{borderRadius: "0px"}} src="https://res.cloudinary.com/jcreatesifi1124/image/upload/v1654280133/relationship-testing/AdobeStock_62815750_edited-200px_mjvye6.jpg" alt="image for dna relationship testing" width={100} />
                </div>
            </div>
            <div className={textSection}>
                <p>
                20+ years of experience in Non-legal/Legal Relationship Testing for Paternity, Siblingship, Family Studies and Immigration. Samples are analyzed at our Accredited Laboratory in Lombard, IL. Results in 3-5 business days.
                </p>
            </div>
        </div>
    )
}